<template>
  <div class="outer-container">
    <div class="inner-container">
      <template v-if="selectedView == 'party'">
        <template v-for="(partyName, party, index) in parties">
          <div class="party-tile" :class="`party-${index+1}`" :key="index + '-party'" @click="nav('party', party)">
            <div class="logo-container" :key="index + '-party'">
              <PartyLogo :party="party" :size="'med'" :circle="true" :grayscale="false" :tooltip="false" />
              <div class="party-percentage">
                <p>{{printPartyName(party)}}</p>
              </div>
            </div>
          </div>
        </template>
      </template>

      <template v-else-if="selectedView == 'topic'">
        <template v-for="(topic, index) in lawsPageLaws">
          <div class="topic-tile" :key="index" :class="`tile-${index+1}`" @click="nav('topic', topic.topicID)">
            <div class="party-percentage">
              <h4>{{topic.topicName}}</h4>
            </div>
          </div>
        </template>

        <!-- <div class="tile tile-empty" v-if="lawsPageLaws.length<2"></div>
          <div class="tile tile-empty" v-if="lawsPageLaws.length<3"></div>
          <div class="tile tile-empty" v-if="lawsPageLaws.length<4"></div>
          <div class="tile tile-empty" v-if="lawsPageLaws.length<5"></div>
          <div class="tile tile-empty" v-if="lawsPageLaws.length<6"></div>            -->

        <div class="arrow-container">
          <div class="arrow-box" @click="currentLawsPage>0?currentLawsPage--:''" :class="arrowBoxClass.previous">
            <img src="@/assets/icons/back-black.svg" height="60%" alt="back">
          </div>
          <div class="page-box">
            <p>{{currentLawsPage+1}}/{{lawsPagesTotal}}</p>
          </div>
          <div class="arrow-box" @click="currentLawsPage<lawsPagesTotal-1?currentLawsPage++:''" :class="arrowBoxClass.next">
            <img src="@/assets/icons/next-black.svg" height="60%" alt="next">
          </div>
        </div>
      </template>

      <!-- <template v-else>
        <div class="mid-container">
          <div class="text-container">
            <h1 class="title">Dyk ned i et politisk parti eller et politisk emne</h1>
            <p>Kend dit folketing har samlet alle lovforslag, afstemninger og politiske taler fra folketingssalen siden 2011, så du kan dykke ned i, hvad der sker i Folketinget. </p>
            <p>Alt du trænger at gøre er at vælge mellem et politisk parti eller tema.</p>
          </div>
        </div>
      </template> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.outer-container {
  height: 100%;
  width: 100%;
  background: #fff;
}
.inner-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.mid-container {
  padding: 0;
  height: 100%;
  width: 60%;
  position: relative;
  display: flex;
  .text-container {
    align-self: center;
    box-sizing: border-box;
    position: relative;
    text-align: left;
    p {
      font-family: "roboto-condensed";
      font-weight: 500;
      font-size: 150%;
      line-height: 1.5em;
      color: #000;
    }
  }
}
.party-tile,
.topic-tile {
  padding: 10px;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  h4 {
    font-family: "futura-pt-condensed", sans-serif;
    font-style: italic;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 150%;
    margin: 0;
  }
  p.minutes-text {
    font-family: "futura-pt";
    font-weight: 700;
    font-size: 70%;
  }
}
.party-tile {
  width: calc(100% / 3);
  height: calc(100% / 3);
  filter: grayscale(100%);
  transition: all 0.5s;
  &:hover,
  &.active {
    filter: grayscale(0%);
    background-color: lighten($color-lightgrey, 0.3);
    .party-percentage {
      visibility: visible;
    }
  }
}
.topic-tile {
  width: 50%;
  height: calc(90% / 3);
}
.tile-1 {
  background: #f2f2f2;
}
.tile-2 {
  background: #f8ce54;
}
.tile-3 {
  background: #64d1e2;
}
.tile-4 {
  background: #eb8a54;
}
.tile-5 {
  background: #d85b53;
}
.tile-6 {
  background: #b3b3b3;
}
.tile-empty {
  background: rgba(0, 0, 0, 0.1);
}
.logo-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 40%;
  .party-percentage {
    position: absolute;
    padding: 2px;
    top: 110%;
    left: 50%;
    min-width: 200%;
    transform: translateX(-50%);
    padding: 5px;
    visibility: hidden;
    p {
      margin: 0;
      padding: 0;
      font-size: 100%;
      font-family: "futura-pt-condensed";
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}
.logo-container:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.arrow-container {
  display: flex;
  width: 100%;
  height: 6%;
  margin: 1.5% 0;
  justify-content: space-between;
  // background: red;
}
.arrow-box,
.page-box {
  background: $color-grey;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
}
.arrow-box {
  width: 40%;
  cursor: pointer;
}
.page-box {
  width: 16%;
  p {
    font-family: "futura-pt";
    font-weight: 400;
    font-size: 100%;
  }
}
.arrow-disabled {
  opacity: 0.2;
  cursor: default;
}
</style>

<script>
import parties from "@/assets/parties/parties.json";
import PartyLogo from "@/components/PartyLogo";

export default {
  props: ["selectedView", "topicsList"],
  components: {
    PartyLogo
  },
  data() {
    return {
      parties,
      currentLawsPage: 0,
      interval: null,
      counter: 0,
      max: 8,
      play: true,
      sortedTopicList: [
        { topicID: 7, topicName: "Flygtninge & Indvandrere" },
        { topicID: 17, topicName: "Retspolitik & Justitsvæsen" },
        { topicID: 0, topicName: "Arbejdsmarked & Beskæftigelse" },
        { topicID: 24, topicName: "Uddannelse & Forskning" },
        { topicID: 15, topicName: "Miljø & Natur" },
        { topicID: 2, topicName: "Børn, Unge & Familie" },
        { topicID: 6, topicName: "Erhverv & Finans" },
        { topicID: 3, topicName: "Klima, Energi & Forsyning" },
        { topicID: 4, topicName: "EU" },
        { topicID: 21, topicName: "Sundhed" },
        { topicID: 19, topicName: "Skatter & Afgifter" },
        { topicID: 5, topicName: "Finanslov & offentlige finanser" },
        { topicID: 16, topicName: "Offentlig forvaltning" },
        { topicID: 12, topicName: "Kommuner & Regioner" },
        { topicID: 23, topicName: "Transport & Infrastruktur" },
        { topicID: 1, topicName: "By & Bolig" },
        { topicID: 20, topicName: "Socialområdet" },
        { topicID: 22, topicName: "Teknologi, IT & Digitalisering" },
        { topicID: 13, topicName: "Kultur, Fritid & Tro" },
        { topicID: 25, topicName: "Udenrigspolitik" },
        { topicID: 14, topicName: "Ligestilling & Minoritetsrettigheder" },
        { topicID: 9, topicName: "Forsvar & Militær" },
        { topicID: 18, topicName: "Rigsfællesskabet" },
        { topicID: 10, topicName: "Fødevarer" }
      ]
    };
  },
  methods: {
    printPartyName: function(party) {
      return this.parties[party].name;
    },
    nav: function(section, id) {
      if (section === "party") this.play = false;
      let url = section === "party" ? "/party/" + id : "/topic/" + id;
      this.$router.push(url);
    }
  },
  computed: {
    lawsPagesTotal: function() {
      return Math.ceil(this.sortedTopicList.length / 6);
    },
    lawsPageLaws: function() {
      let start = 0 + this.currentLawsPage * 6;
      let end = 6 + this.currentLawsPage * 6;
      return this.sortedTopicList.slice(start, end);
    },
    arrowBoxClass: function() {
      return {
        previous: this.currentLawsPage !== 0 ? "" : "arrow-disabled",
        next:
          this.currentLawsPage !== this.lawsPagesTotal - 1
            ? ""
            : "arrow-disabled"
      };
    }
  },
  watch: {
    selectedView: function() {
      if (this.selectedView === "topic") this.play = false;
      if (this.selectedView === "party") this.play = true;
      // console.log(this.play);
    }
  },
  created() {
    this.interval = setInterval(() => {
      if (this.play) {
        this.counter++;
        let el = document.querySelector(".party-" + this.counter);
        let siblings = n => [...n.parentElement.children].filter(c => c != n);

        let others = siblings(el);
        el.classList.add("active");
        others.forEach(e => e.classList.remove("active"));

        if (this.counter > this.max) this.counter = 0;
      } else {
        clearInterval(this.interval);
      }
    }, 1500);
  }
};
</script>
