import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
const Topic = () =>
  import(/* webpackChunkName: "TopicView" */ "./views/Topic.vue");
const Party = () =>
  import(/* webpackChunkName: "PartyView" */ "./views/Party.vue");
const About = () => import(/* webpackChunkName: "About" */ "./views/About.vue");
const IFrame = () =>
  import(/* webpackChunkName: "iFrame" */ "./views/IFrame.vue");
const IFrameBuilder = () =>
  import(/* webpackChunkName: "iFrame" */ "./views/IFrameBuilder.vue");

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/topic/:topicID",
      name: "topic",
      component: Topic
    },
    {
      path: "/party/:partyID",
      name: "party",
      component: Party
    },
    {
      path: "/about",
      name: "about",
      component: About
    },
    {
      path: "/iframe/:topicID/:period/:section",
      name: "iframe",
      component: IFrame
    },
    {
      path: "/iframebuilder",
      name: "iframeBuilder",
      component: IFrameBuilder
    },
    // catch-all-route that simply redirects to homepage
    { path: "*", redirect: "/" }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      // if the route contains an anchor-link, it should jump to that section
      // An offset of 80px has to be added, because the fixed nav bar is occupying the first 80px
      return { selector: to.hash };
    } else if (savedPosition) {
      // If the back button was clicked, the previous scroll position is recovered
      return savedPosition;
    } else if (to.name != from.name) {
      // If the previous section was a different one then the current, scroll to top
      return { x: 0, y: 0 };
    } else {
      // Otherwise the route should be displayed from the top
      // return { x: 0, y: 0 };
    }
  }
});
