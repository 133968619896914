<template>
  <div class="home">
    <IntroSection />
    <IntroSelectSection id="intro-select-section" :topicsList="topicsList" />
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
// @ is an alias to /src
import axios from "axios";
import IntroSection from "@/sections/introSection/IntroSection.vue";
import IntroSelectSection from "@/sections/IntroSelectSection/IntroSelectSection.vue";

export default {
  name: "home",
  components: {
    IntroSection,
    IntroSelectSection
  },
  data() {
    return {
      topicsList: null
    };
  },
  methods: {
    setTopicsList: function(data) {
      this.topicsList = data;
    }
  },
  beforeRouteEnter(to, from, next) {
    axios
      .get("/static/overview.json")
      .then(response =>
        next(vm =>
          vm.setTopicsList(
            response.data.SectionOverviewStatsAll.govPeriods.allPeriod
          )
        )
      );
  }
};
</script>
