
<template>
  <div class="party-logo-container" :class="[circle?'party-logo-circle':'', grayscale?'party-logo-grayscale':'']">
    <div class="party-logo-image">
      <img :src="partyLogo(party, size)" alt="">
      <div class="tooltip" v-if="tooltip">
        <p>{{partyNames[party].name}}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.party-logo-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    z-index: 99999;
  }
}
.party-logo-image {
  &:hover {
    // z-index: 999;
    .tooltip {
      visibility: visible;
    }
  }

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img {
    width: 100%;
  }
}
.party-logo-circle {
  border-radius: 50%;
  background: white;
  box-shadow: 1px 1px 1px grey;
  .party-logo-image {
    border-radius: 50%;
    width: 85%;
    height: 85%;
  }
}
.tooltip {
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  height: 2vw;
  width: 8vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-lightgrey;
  pointer-events: none;
  visibility: hidden;
  p {
    margin: 0;
    font-family: "futura-pt-condensed", sans-serif;
    font-weight: 700;
  }
}
.party-logo-grayscale {
  img {
    filter: grayscale(100%);
    &:hover {
      filter: grayscale(0%);
    }
  }
}
</style>

<script>
import partyNames from "@/assets/parties/parties.json";
export default {
  props: {
    party: {},
    size: {
      default: "small"
    },
    circle: {
      default: false
    },
    tooltip: {
      default: true
    },
    grayscale: {
      default: false
    }
  },
  data() {
    return {
      partyNames
    };
  },
  methods: {
    partyLogo: function(party, size) {
      return require(`@/assets/parties/logos/new/${party}_${size}.png`);
    }
  }
};
</script>
