<template>
  <div class="outer-container party" :class="[{party:selectedView == 'party'}, {topic:selectedView == 'topic'}]">
    <div class="white-container">
      <h1 class="title">Dyk ned i et politisk parti eller tema</h1>
      <p>Kend Dit Folketing har samlet alle lovforslag, afstemninger og politiske taler fra folketingssalen siden 2011, så du kan dykke ned i, hvad der sker i Folketinget. </p>
      <h1 class="selection party" @click="selectedView = 'party'" :class="{active:selectedView == 'party'}">Politiske partier</h1>
      <br ><br />
      <h1 class="selection topic" @click="selectedView = 'topic'" :class="{active:selectedView == 'topic'}">Politiske temaer</h1>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.outer-container {
  padding: 0;
  height: 100%;
  background: $color-yellow3;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .white-container {
    box-sizing: border-box;
    position: relative;
    text-align: left;
    align-self: center;
    width: 70%;
    h1.selection {
      text-align: left;
      font-family: "futura-pt-condensed", sans-serif;
      font-style: italic;
      font-weight: 800;
      text-transform: uppercase;
      color: #fff;
      padding: 3px 10px 3px 10px;
      cursor: pointer;
      display: inline-block;
    }
    h1.party:hover,
    h1.party.active {
      background-color: $color-orange;
    }
    h1.topic:hover,
    h1.topic.active {
      background-color: $color-blue;
    }
    h1.active {
      text-decoration: underline;
    }
    p {
      text-align: left;
      font-family: "roboto-condensed";
      font-weight: 500;
      font-size: 120%;
      color: #000;
    }
  }
}
.outer-container.party {
  background-color: $color-orange;
}
.outer-container.topic {
  background-color: $color-blue;
}
.show-on-mobile {
  display: none;
  background: $color-yellow;
  width: 50%;
  border-radius: 2px;
  font-size: 150%;
}

@media (max-width: $bp-narrow) {
  .hide-on-mobile {
    display: none;
  }
  .show-on-mobile {
    display: block;
  }
  .white-container {
    h1.selection {
      padding: 3px 5px 3px 5px !important;
    }
    p {
      font-size: 150% !important;
    }
  }
}
</style>

<script>
export default {
  props: [],
  data() {
    return {
      selectedView: "party"
    };
  },
  methods: {},
  computed: {},
  watch: {
    selectedView: function() {
      this.$emit("selectedView", this.selectedView);
    }
  }
};
</script>
