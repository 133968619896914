<template>
  <div id="app">
    <NavBar v-if="$route.name!=='iframe' && $route.name!=='iframeBuilder'" />
    <!-- <transition name="fade" mode="out-in"> -->
    <router-view />
    <!-- </transition> -->
  </div>
</template>


<style lang="scss">
@import "@/assets/styles/settings.scss";
@import url("https://use.typekit.net/esb7hwm.css");

body {
  margin: 0;
}
#app {
  //font-family: "Futura", Helvetica, Arial, sans-serif;
  font-family: "roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 1vw;
  // // 80px is the height of the fixed nav bar
  min-height: calc(100vh - 80px);
  margin-top: 60px;
}
.link,
.link:hover {
  // global styling for <a></a>-tags
  color: black;
  text-decoration: none; /* no underline */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: 0.5s;
}
.slide-enter {
  opacity: 0;
}
.slide-leave-to /* .slide-leave-active below version 2.1.8 */ {
  transform: translateY(-100%);
}

@media (max-width: $bp-narrow) {
  #app {
    // Font size is doubled on small screens
    font-size: 2vw;
  }
}
</style>

<script>
import NavBar from "@/components/NavBar";
export default {
  name: "App",
  components: {
    NavBar
  }
};
</script>
