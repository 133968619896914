<template>
  <Section>
    <HalfSection>
      <IntroSelectLeft @selectedView="selectedView = $event" />
    </HalfSection>
    <HalfSection>
      <IntroSelectRight :selectedView="selectedView" :topicsList="topicsList" />
    </HalfSection>
  </Section>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.show-on-mobile {
  display: none;
  background: $color-yellow;
  width: 50%;
  border-radius: 2px;
  font-size: 150%;
}
@media (max-width: $bp-narrow) {
  .hide-on-mobile {
    display: none;
  }
  .show-on-mobile {
    display: block;
  }
}
</style>

<script>
import Section from "@/components/Section";
import HalfSection from "@/components/HalfSection";
import IntroSelectLeft from "./IntroSelectLeft";
import IntroSelectRight from "./IntroSelectRight";
export default {
  props: ["topicsList"],
  components: {
    Section,
    HalfSection,
    IntroSelectLeft,
    IntroSelectRight
  },
  data() {
    return {
      selectedView: "party"
    };
  }
};
</script>
