<template>
  <div class="nav-bar">
    <div class="home-logo">
      <router-link class="link" to="/">
        <img :src="logo" />
      </router-link>
    </div>
    <div class="about-link">
      <router-link class="link" to="/about">
        <p>INFO</p>
      </router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.nav-bar {
  position: fixed;
  background: white;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  .home-logo {
    position: absolute;
    left: 5px;
    top: 10px;
    width: 100px;
    height: 50px;
    img {
      height: 80%;
      width: auto;
    }
    p {
      margin: 0;
      font-family: "futura-pt";
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1.3rem;
      text-align: left;
    }
  }
  .about-link {
    position: absolute;
    right: 10px;
    p {
      margin: 0;
      margin-right: 6px;
      font-family: "futura-pt";
      font-weight: 500;
      text-transform: uppercase;
      font-size: 14px;
      cursor: pointer;
      padding: 4px;
    }
    p:hover {
      background: #000;
      color: #fff;
    }
  }
}
@media (max-width: $bp-narrow) {
  .nav-bar {
    .home-logo {
      position: absolute;
      top: 0;
      height: 60px;
      img {
        position: absolute;
        height: 50%;
        width: auto;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }
}
</style>

<script>
export default {
  computed: {
    logo: function() {
      return require("@/assets/icons/logo.svg");
    }
  }
};
</script>
