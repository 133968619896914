<template>
  <div class="halfsection-outer-container" :class="overflow ? '' : 'align-top'">
    <div class="halfsection-container" :class="overflow ? '' : 'overflow-visible'">
      <div :class="[(overflow ? 'halfsection-content' : 'halfsection-content-full-size')]">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.halfsection-outer-container {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.align-top {
  justify-content: flex-start !important;
}
@media (max-width: $bp-narrow) {
  .halfsection-outer-container {
    width: 100%;
    height: 100%;
  }
}
.halfsection-container {
  height: 100%;
  width: 100%;
  padding-top: 100%;
  max-width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}
.overflow-visible {
  padding-top: 0% !important;
  overflow-y: auto !important;
}
.halfsection-content {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.halfsection-content-full-size {
  box-sizing: border-box;
  position: relative;
}
</style>

<script>
export default {
  props: {
    stretch: {
      default: false
    },
    overflow: {
      default: true
    }
  }
};
</script>
