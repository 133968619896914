<template>
  <Section>
    <div class="outer-container" id="section-intro-container">
      <div class="inner-container">
        <div class="logo-holder">
          <div class="holder">
            <h1>KEND
              <span class="dit" @click="smoothScrollTo('#intro-select-section')">
                DIT
                <svg class="highlight" x="0px" y="0px" viewBox="0 0 346 198">
                  <polygon class="st0" points="29.1,6.7 4.7,191.3 318.8,190.5 341.3,6.7 	" />
                </svg>
              </span>
              <br />
              FOLKETING</h1>
          </div>
          <div class="intro-text">
            <p>Der er meget snak om det politiske spil. Men hvad er det, politikerne rent faktisk siger og gør? Kom med bagom de store overskrifter og den enkle retorik, og se, hvad der egentlig fylder noget i Folketinget.</p>
          </div>
        </div>
        <div class="down-arrow" @click="[smoothScrollTo('#intro-select-section'), bounceClicked=true]" :class="!bounceClicked?'bounce':''"><img :src="arrowDown" alt="scroll down"></div>
        </div>

      </div>
  </Section>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
.outer-container {
  height: 100%;
  min-height: calc(100vh - 60px);
  width: 100%;
  background: $color-yellow3;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}
.inner-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-holder {
  position: relative;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.holder {
  width: 80%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}
h1 {
  margin: 0 10%;
  padding: 0;
  font-size: 1000%;
  text-align: left;
  font-family: "futura-pt-condensed", sans-serif;
  font-style: italic;
  font-weight: 800;
  line-height: 100%;
  color: #e8504d;
  z-index: 10;
}
.dit {
  position: relative;
  z-index: 10;
  cursor: pointer;
  // background: beige;
}
.dit:hover {
  color: #000;
}
.highlight {
  position: absolute;
  top: 26%;
  left: -11.5%;
  width: 100%;
  fill: #fff;
  height: auto;
  z-index: -1;
}
.intro-text {
  max-width: 100%;
  margin-top: 5%;
}
p {
  margin: 0 10%;
  text-align: center;
  font-family: "roboto-condensed";
  font-weight: 500;
  font-size: 150%;
  line-height: 1.5em;
  color: #000;
}
.down-arrow {
  position: absolute;
  bottom: 2%;
  height: 5%;
  cursor: pointer;
  img {
    height: 100%;
  }
}
@media (max-width: $bp-narrow) {
  .down-arrow {
    bottom: 45px;
  }
  .logo-holder {
    max-width: 80%;
  }
  h1 {
    font-size: 700%;
    line-height: 100%;
  }
  svg.highlight {
    position: absolute;
    top: 25%;
    left: -11.5%;
    width: 100%;
  }
  p {
    font-size: 150%;
  }
}

@keyframes bounceDown {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.bounce {
  animation: bounceDown 2s infinite;
}
</style>

<script>
import Section from "@/components/Section";
export default {
  components: {
    Section
  },
  data() {
    return {
      bounceClicked: false,
      topOffset: 60
    };
  },
  computed: {
    arrowDown: function() {
      return require("@/assets/icons/down-black.svg");
    }
  },
  methods: {
    handleScroll: function() {
      this.scrollPosition = window.scrollY;
    },
    smoothScrollTo: function(target) {
      let header = this.topOffset;
      let scrollY = document.querySelector(target).offsetTop - this.topOffset;
      window.scroll({ top: scrollY, behavior: "smooth" });
    },
    setPositions: function() {
      this.periodSelectorPosition =
        document.querySelector("#section-intro-container").offsetHeight * 0.8 +
        40;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.setPositions();
    window.addEventListener("resize", this.setPositions());
  },
  watch: {
    scrollPosition: function() {
      if (
        this.periodSelectorPosition &&
        this.scrollPosition >= this.periodSelectorPosition
      ) {
        this.stickyPeriods = true;
      } else {
        this.stickyPeriods = false;
      }

      if (this.scrollPosition > this.topOffset) {
        this.topicMenu = true;
      } else {
        this.topicMenu = false;
      }
    }
  }
};
</script>
