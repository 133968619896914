import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import smoothscroll from "smoothscroll-polyfill";

// Maybe move this to the SectionWordBrowser file?
import { Laue } from "laue";
Vue.use(Laue);

smoothscroll.polyfill();

Vue.config.productionTip = false;

Vue.prototype.$eventBus = new Vue(); // Global event bus

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
